import angular from 'angular';
import { react2angular } from 'react18-react2angular';
import createSharedContext from 'react2angular-shared-context';
import './lodashMixins';
import './yupMixins';
/*order-break*/
import AchievementCertEditorPage from './achievement/components/AchievementCertEditorPage';
import AchievementEditorPage from './achievement/components/AchievementEditorPage';
import AchievementFieldEditorPage from './achievement/components/AchievementFieldEditorPage';
import AchievementInstanceEditorPage from './achievement/components/AchievementInstanceEditorPage';
import AchievementInstancesPage from './achievement/components/AchievementInstancesPage';
import AchievementTemplateManagerPage from './achievement/components/AchievementTemplateManagerPage';
import AchievementsListPage from './achievement/components/AchievementsListPage';
import AddGroup from './manage-groups/components/AddGroup';
import AppUserCustomFields from './app-users/components/AppUserCustomFields';
import AppUserEditorPage from './app-users/components/AppUserEditorPage';
import AppUserListPage from './app-users/components/AppUserListPage';
import AttendanceEventAttendeePage from './attendance-tracking/components/AttendanceEventAttendeePage';
import AttendanceEventEditorPage from './attendance-tracking/components/AttendanceEventEditorPage';
import AttendanceEventPage from './attendance-tracking/components/AttendanceEventPage';
import CalendarEventsEditorPage from './calendar-events/components/CalendarEventsEditorPage';
import CalendarEventsListPage from './calendar-events/components/CalendarEventsListPage';
import CohortEditorPage from './cohort/components/CohortEditorPage';
import CohortListPage from './cohort/components/CohortListPage';
import CohortMatchesPage from './cohort/components/CohortMatchesPage';
import CollectionEditorPage from './collection/components/CollectionEditorPage';
import CollectionItemEditorPage from './collection/components/CollectionItemEditorPage';
import CollectionItemPublishPage from './collection/components/CollectionItemPublishPage';
import CollectionListPage from './collection/components/CollectionListPage';
import CollectionSettingsPage from './collection/components/CollectionSettingsPage';
import CsvExportEditorPage from './app-users/components/CsvExportEditorPage';
import CustomPageEditor from './custom-page/components/CustomPageEditor';
import CustomPageList from './custom-page/components/CustomPageList';
import FormBuilderPage from './forms/components/FormBuilderPage';
import FormInstanceEditorPage from './forms/components/FormInstanceEditorPage';
import FormInstancesPage from './forms/components/FormInstancesPage';
import FormsListPage from './forms/components/FormsListPage';
import GeneralFilesEditorPage from './files/components/GeneralFilesEditorPage';
import GeneralFilesListPage from './files/components/GeneralFilesListPage';
import GrowlContainer from './shared/growl/components/GrowlContainer';
import ItrsCourseCreatePage from './itrs/components/ItrsCourseCreatePage';
import ItrsCourseListPage from './itrs/components/ItrsCourseListPage';
import ItrsCourseViewPage from './itrs/components/ItrsCourseViewPage';
import ItrsDashboardPage from './itrs/components/ItrsDashboardPage';
import ItrsMailLogItemPage from './itrs/components/ItrsMailLogItemPage';
import ItrsMailLogPage from './itrs/components/ItrsMailLogPage';
import ItrsSessionCreatePage from './itrs/components/ItrsSessionCreatePage';
import ItrsSessionViewPage from './itrs/components/ItrsSessionViewPage';
import ItrsReportsPage from './itrs/components/ItrsReportsPage';
import ItrsTravelConfirmationPage from './itrs/components/travel/ItrsTravelConfirmationPage';
import ItrsTravelRequestsPage from './itrs/components/travel/ItrsTravelRequestsPage';
import LeadGenAttendeesPage from './lead-generation/components/LeadGenAttendeesPage';
import LeadGenEventEditorPage from './lead-generation/components/LeadGenEventEditorPage';
import LeadGenEventPage from './lead-generation/components/LeadGenEventPage';
import ManageAssetListPage from './manage-assets/pages/ManageAssetListPage';
import ManageGroupsCsvExportEditorPage from './manage-groups/components/ManageGroupsCsvExportEditorPage';
import ManageGroupsEditMembers from './manage-groups/components/ManageGroupsEditMembers';
import ManageGroupsListPage from './manage-groups/components/ManageGroupsListPage';
import MapsDetailPage from './new-maps/components/MapsDetailPage';
import MapsKmlEditor from './new-maps/components/MapsKmlEditor';
import MapsListPage from './new-maps/components/MapsListPage';
import MeetsEditorPage from './meets/components/MeetsEditorPage';
import MeetsListPage from './meets/components/MeetsListPage';
import MenuEditorPage from './menu/components/MenuEditorPage';
import MenuItemEditorPage from './menu/components/MenuItemEditorPage';
import MenuItemPublishPage from './menu/components/MenuItemPublishPage';
import MenuListPage from './menu/components/MenuListPage';
import NavBarEditorPage from './navbar/components/NavbarEditorPage';
import NavBarItemEditorPage from './navbar/components/NavbarItemEditorPage';
import NavBarItemPublishPage from './navbar/components/NavbarItemPublishPage';
import NewAchievements from './achievement/achievement-creator/index';
import PrivacyPage from './privacy/components/PrivacyPage';
import PushNotificationEditorPage from './push/components/PushNotificationEditorPage';
import PushNotificationListPage from './push/components/PushNotificationListPage';
import QrScannerEditorPage from './qrscanner/components/QrScannerEditorPage';
import QrScannerListPage from './qrscanner/components/QrScannerListPage';
import QrScannerLogExport from './qrscanner/components/QrScannerLogExport';
import QrScannerLogs from './qrscanner/components/QrScannerLogs';
import QrScannerTargetEditor from './qrscanner/components/QrScannerTargetEditor';
import QrScannerTargetGroupEditor from './qrscanner/components/QrScannerTargetGroupEditor';
import QrScannerTargetGroups from './qrscanner/components/QrScannerTargetGroups';
import QrScannerTargets from './qrscanner/components/QrScannerTargets';
import QuicksightReportPage from './quicksight/components/QuicksightReportPage';
import QuizBuilderPage from './quizzes/components/QuizBuilderPage';
import QuizInstanceEditorPage from './quizzes/components/QuizInstanceEditorPage';
import QuizInstancesPage from './quizzes/components/QuizInstancesPage';
import QuizzesListPage from './quizzes/components/QuizzesListPage';
import ReduxStore from './store/index';
import RegistrationsEditorPage from './registrations/components/RegistrationsEditorPage';
import RegistrationsListPage from './registrations/components/RegistrationsListPage';
import ReportingPage from './reporting/components/ReportingPage';
import SampleComponent from './sample/components/SampleComponent';
import SurveyInstanceEditorPage from './surveys/components/SurveyInstanceEditorPage';
import SurveyInstancesPage from './surveys/components/SurveyInstancesPage';
import SurveysListPage from './surveys/components/SurveysListPage';
import TargetAudienceEditorPage from './target-audience/components/TargetAudienceEditorPage';
import TargetAudienceListPage from './target-audience/components/TargetAudienceListPage';
import TemplateItemsEditorPage from './template/components/TemplateItemsEditorPage';
import TemplateItemsListPage from './template/components/TemplateItemsListPage';
import WorkflowListPage from './workflow/components/WorkflowListPage';
import WorkflowLogPage from './workflow/components/WorkflowLogPage';

const cmsReactContext = createSharedContext(ReduxStore);
const module = angular
  .module('react-components', [])
  .component(
    'cmsReactContext',
    react2angular(cmsReactContext.component, [
      'cmsConfig',
      'selectedTenant',
      'selectedSubtenant',
      'navigateToState',
      'showLinkSearch',
      'showAudienceModal',
      'addReport',
      'getReports',
      'showImportDialog',
      'notifyContentUpdated',
      'children',
    ])
  );

const components = {
  achievementCertEditorPage: [AchievementCertEditorPage],
  achievementEditorPage: [AchievementEditorPage, ['achievementId']],
  achievementFieldEditorPage: [AchievementFieldEditorPage],
  achievementInstanceEditorPage: [
    AchievementInstanceEditorPage,
    ['achievementId', 'achievementInstanceId'],
  ],
  achievementInstancesPage: [AchievementInstancesPage, ['achievementId']],
  achievementServicePage: [NewAchievements],
  achievementTemplateManagerPage: [AchievementTemplateManagerPage],
  achievementsListPage: [AchievementsListPage],
  addGroup: [AddGroup, ['groupId']],
  appUserCustomFields: [AppUserCustomFields],
  appUserEditorPage: [AppUserEditorPage, ['appUserId']],
  appUserListPage: [AppUserListPage],
  attendanceEventAttendeePage: [AttendanceEventAttendeePage, ['attendanceEventId']],
  attendanceEventEditorPage: [AttendanceEventEditorPage, ['attendanceEventId']],
  attendanceEventPage: [AttendanceEventPage],
  calendarEventsEditorPage: [CalendarEventsEditorPage, ['calendarEventId']],
  calendarEventsListPage: [CalendarEventsListPage],
  cohortEditorPage: [CohortEditorPage, ['cohortId']],
  cohortListPage: [CohortListPage],
  cohortMatchesPage: [CohortMatchesPage, ['cohortId', 'credentialCode']],
  collectionEditorPage: [CollectionEditorPage, ['collectionId']],
  collectionItemEditorPage: [CollectionItemEditorPage, ['collectionId', 'itemId']],
  collectionItemPublishPage: [
    CollectionItemPublishPage,
    ['collectionId', 'successGrowlId', 'returnPageId', 'returnPageName', 'returnPageLabel'],
  ],
  collectionListPage: [CollectionListPage],
  collectionSettingsPage: [CollectionSettingsPage, ['collectionId']],
  csvExportEditorPage: [CsvExportEditorPage],
  customPageEditor: [CustomPageEditor, ['customPageId']],
  customPageList: [CustomPageList],
  formBuilderPage: [FormBuilderPage, ['formId']],
  formInstanceEditorPage: [FormInstanceEditorPage, ['formId', 'formInstanceId']],
  formInstancesPage: [FormInstancesPage, ['formId']],
  formsListPage: [FormsListPage],
  generalFilesEditorPage: [GeneralFilesEditorPage, ['generalFilesId']],
  generalFilesListPage: [GeneralFilesListPage],
  growlContainer: [GrowlContainer, ['groupId']],
  itrsCourseCreatePage: [ItrsCourseCreatePage, ['courseId']],
  itrsCourseListPage: [ItrsCourseListPage],
  itrsCourseViewPage: [ItrsCourseViewPage, ['courseId']],
  itrsDashboardPage: [ItrsDashboardPage],
  itrsMailLogItemPage: [ItrsMailLogItemPage, ['itemId']],
  itrsMailLogPage: [ItrsMailLogPage],
  itrsSessionCreatePage: [ItrsSessionCreatePage, ['sessionId', 'courseId']],
  itrsSessionViewPage: [ItrsSessionViewPage, ['sessionId']],
  itrsReportsPage: [ItrsReportsPage],
  itrsTravelConfirmationPage: [ItrsTravelConfirmationPage, ['travelRequestId']],
  itrsTravelRequestsPage: [ItrsTravelRequestsPage],
  leadGenAttendeesPage: [
    LeadGenAttendeesPage,
    ['leadGenId', 'eventName', 'resultType', 'ingestType'],
  ],
  leadGenEventEditorPage: [LeadGenEventEditorPage, ['leadGenEventId']],
  leadGenEventPage: [LeadGenEventPage],
  manageAssetListPage: [ManageAssetListPage],
  manageGroupsCsvExportEditorPage: [ManageGroupsCsvExportEditorPage],
  manageGroupsEditMembers: [
    ManageGroupsEditMembers,
    ['groupId', 'groupName', 'groupKey', 'groupCanAssign'],
  ],
  manageGroupsListPage: [ManageGroupsListPage],
  mapsDetailPage: [MapsDetailPage, ['loaderParams', 'mapId']],
  mapsKmlEditor: [MapsKmlEditor, ['itemId', 'mapId', 'kmlType']],
  mapsListPage: [MapsListPage],
  meetsEditorPage: [MeetsEditorPage, ['meetId', 'meetType']],
  meetsListPage: [MeetsListPage, ['meetType']],
  menuEditorPage: [MenuEditorPage, ['menuId']],
  menuItemEditorPage: [MenuItemEditorPage, ['menuId', 'itemId']],
  menuItemPublishPage: [
    MenuItemPublishPage,
    ['menuId', 'successGrowlId', 'returnPageId', 'returnPageName', 'returnPageLabel'],
  ],
  menuListPage: [MenuListPage],
  navbarEditorPage: [NavBarEditorPage, ['menuId']],
  navbarItemEditorPage: [NavBarItemEditorPage, ['menuId', 'itemId']],
  navbarItemPublishPage: [
    NavBarItemPublishPage,
    ['menuId', 'successGrowlId', 'returnPageId', 'returnPageName', 'returnPageLabel'],
  ],

  privacyPage: [PrivacyPage],
  pushNotificationEditorPage: [
    PushNotificationEditorPage,
    ['pushNotificationId', 'allowEdit', 'baseNotificationId'],
  ],
  pushNotificationListPage: [PushNotificationListPage],
  qrScannerEditorPage: [QrScannerEditorPage, ['qrscannerId']],
  qrScannerListPage: [QrScannerListPage],
  qrScannerLogExport: [QrScannerLogExport, ['exportOptions']],
  qrScannerLogs: [QrScannerLogs, ['qrscannerId']],
  qrScannerTargetEditor: [QrScannerTargetEditor, ['targetId']],
  qrScannerTargetGroupEditor: [QrScannerTargetGroupEditor, ['targetGroupId']],
  qrScannerTargetGroups: [QrScannerTargetGroups],
  qrScannerTargets: [QrScannerTargets, ['qrscannerId']],
  quicksightReportPage: [QuicksightReportPage],
  quizBuilderPage: [QuizBuilderPage, ['quizId']],
  quizInstanceEditorPage: [QuizInstanceEditorPage, ['quizId', 'quizInstanceId']],
  quizInstancesPage: [QuizInstancesPage, ['quizId']],
  quizzesListPage: [QuizzesListPage],
  registrationEditor: [RegistrationsEditorPage, ['registrationId']],
  registrationsListPage: [RegistrationsListPage],
  reportingPage: [ReportingPage],
  sampleComponent: [SampleComponent],
  surveyInstanceEditorPage: [SurveyInstanceEditorPage, ['surveyId', 'surveyInstanceId']],
  surveyInstancesPage: [SurveyInstancesPage, ['surveyId']],
  surveysListPage: [SurveysListPage],
  targetAudienceEditorPage: [TargetAudienceEditorPage, ['targetAudienceId']],
  targetAudienceListPage: [TargetAudienceListPage],
  templateItemEditorPage: [TemplateItemsEditorPage, ['templateItemId']],
  templateItemsListPage: [TemplateItemsListPage],
  workflowListPage: [WorkflowListPage],
  workflowLogPage: [WorkflowLogPage, ['workflowType']],
};

Object.entries(components).forEach(([pageName, [PageComponent, pageParams = []]]) => {
  module.component(pageName, react2angular(cmsReactContext.use(PageComponent), pageParams));
});
