import React from 'react';
import CmsItemListSection from '../../../shared/cmsPage/components/CmsItemListSection';
import useArray from '../../../shared/util/hooks/useArray';
import { ItrsSessionConstants } from '../../definitions';
import useItrsActions from '../../hooks/useItrsActions';
import useTravelConfigurations from '../../hooks/useTravelConfigurations';

const TravelTab = ({ apiParams }) => {
  const { travelRequestsPageId, typeName } = ItrsSessionConstants;
  const { viewSession } = useItrsActions(ItrsSessionConstants);
  const { columns, api } = useTravelConfigurations(apiParams);
  const itemActions = useArray(viewSession);

  return (
    <CmsItemListSection
      pageId={travelRequestsPageId}
      typeName={typeName}
      toolbarActions={[]}
      itemActions={itemActions}
      columns={columns}
      itemApi={api}
      showSearchBar={false}
    />
  );
};

export default TravelTab;
