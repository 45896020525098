import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import BackButton from '../../../shared/cmsPage/components/BackButton';
import CmsPage from '../../../shared/cmsPage/components/CmsPage';
import { ItrsSessionConstants } from '../../definitions';
import travelApi from '../../api/travelApi';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ItrsTravelConfirmSection from './ItrsTravelConfirmSection';

const Heading = ({ children, subheading = false, sx = {}, ...rest }) => (
  <Typography
    variant={subheading ? 'subtitle1' : 'h6'}
    sx={(theme) => ({
      backgroundColor: subheading ? theme.palette.bootstrap.grey : theme.palette.bootstrap.blue,
      color: 'white',
      padding: theme.spacing(1, 1),
      ...sx,
    })}
    {...rest}
  >
    {children}
  </Typography>
);

const Detail = ({ label, value }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <Box>
      <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
        {`${label}:`}
      </Typography>
    </Box>
    <Box sx={{ pl: '1rem' }}>
      <Typography variant="body1" gutterBottom>
        {value}
      </Typography>
    </Box>
  </Box>
);

const ItrsTravelConfirmationPage = (props) => {
  const { travelRequestId } = props;
  const theme = useTheme();
  const [item, setItem] = useState({});

  useEffect(() => {
    const loadReq = async () => {
      const req = await travelApi().loadRecord(travelRequestId);
      setItem(req);
    };
    loadReq();
  }, [travelRequestId]);

  //confirmation
  const travelConfirmation = item?.travelConfirmation;

  //top
  const fullName = `${item?.formInputs?.firstName} ${item?.formInputs?.lastName}`;
  const dateOfBirth = item?.formInputs?.DATE_OF_BIRTH;
  const gender = item?.formInputs?.GENDER;
  const phone = item?.formInputs?.MOBILE;
  const email = item?.formInputs?.email;

  //dorm room
  const needDormRoom = item?.formInputs?.NEED_DORM_ROOM;
  const checkinDate = travelConfirmation?.travelTo?.ArrivalDate;
  const checkoutDate = travelConfirmation?.departFrom?.DepartDate;

  //airfare
  const departureAirport = item?.formInputs?.departureAirport;
  const departureCity = item?.formInputs?.departureCity;
  const departureDate = travelConfirmation?.travelTo?.DepartDate;
  const groundTransportation = item?.formInputs?.groundTransportation;
  const needAirfare = item?.formInputs?.NEED_AIRFARE;
  const preferedAirport = item?.formInputs?.PREFERRED_AIRPORT;
  const preferredAirline = travelConfirmation?.travelTo?.Airline;
  const tsaPrecheck = item?.formInputs?.TSA_PRECHECK;

  //airline rewards
  const airCanadaAeroplan = item?.formInputs?.AIR_CANADA_AEROPLAN;
  const americanAdvantage = item?.formInputs?.AMERICAN_ADVANTAGE;
  const deltaSkyMiles = item?.formInputs?.DELTA_SKYMILES;
  const jetBlueTrueBlue = item?.formInputs?.JET_BLUE_TRUE_BLUE;
  const otherAirlineRewards = item?.formInputs?.OTHER_AIRLINE_REWARDS;
  const southwestRapidRewards = item?.formInputs?.SOUTHWEST_RAPID_REWARDS;
  const unitedMileagePlus = item?.formInputs?.UNITED_MILEAGE_PLUS;
  const virginAmericaElevate = item?.formInputs?.VIRGIN_AMERICA_ELEVATE;
  const westJetRewards = item?.formInputs?.WEST_JET_REWARDS;

  //special requests
  const seatingPreference = item?.formInputs?.SEATING_PREFERENCE;
  const adaNeeds = item?.formInputs?.ADA_NEEDS;
  const additionalInfo = item?.formInputs?.ADDITIONAL_INFO;
  const dietaryNeeds = item?.formInputs?.DIETARY_NEEDS;

  return (
    <CmsPage>
      <Box sx={{ padding: theme.spacing(0) }}>
        <BackButton
          pageName="Session"
          navToParent
          navParams={[ItrsSessionConstants.pluginId, 'default', ItrsSessionConstants.pageTitle]}
        />

        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: theme.spacing(2) }}>
          <div>
            <Typography variant="h4" component="h1" sx={{ flexGrow: 1 }}>
              Travel Request for {item?.formInputs?.firstName} {item?.formInputs?.lastName}
            </Typography>
          </div>
        </Box>

        <Box sx={{ my: theme.spacing(3) }}>
          <Heading gutterBottom sx={{ mb: theme.spacing(1) }}>
            Traveller Information
          </Heading>

          <Detail label="Full Name" value={fullName} />
          <Detail label="Date of Birth" value={dateOfBirth} />
          <Detail label="Gender" value={gender} />
          <Detail label="Cell Phone" value={phone} />
          <Detail label="Email Address" value={email} />

          <Accordion>
            <AccordionSummary
              sx={{
                backgroundColor: '#EEEEEE',
              }}
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="dorm-content"
              id="dorm-header"
            >
              <Typography>Dorm Room Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Detail label="Needs a Dorm Room" value={needDormRoom ? 'Yes' : 'No'} />
              <Detail label="Check-In Date" value={checkinDate} />
              <Detail label="Check-Out Date" value={checkoutDate} />
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              sx={{
                backgroundColor: '#EEEEEE',
              }}
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="airfare-content"
              id="airfare-header"
            >
              <Typography>Airfare Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Detail label="Needs Airfare" value={needAirfare ? 'Yes' : 'No'} />
              <Detail label="Departure City" value={departureCity} />
              <Detail label="Departure Airport" value={departureAirport} />
              <Detail label="Departure Date" value={departureDate} />
              <Detail label="Preferred Departure Airline" value={preferredAirline} />
              <Detail
                label="Ground Transportation from Airport"
                value={groundTransportation ? 'Yes' : 'No'}
              />
              {preferedAirport && <Detail label="Preferred Airport" value={preferedAirport} />}
              {tsaPrecheck && (
                <Detail label="TSA PreCheck / Global Entry / Clear" value={tsaPrecheck} />
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              sx={{
                backgroundColor: '#EEEEEE',
              }}
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="airline-content"
              id="airline-header"
            >
              <Typography>Airline Rewards Program(s)</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {airCanadaAeroplan && (
                <Detail label="Air Canada Aeroplan" value={airCanadaAeroplan} />
              )}
              {americanAdvantage && <Detail label="American Advantage" value={americanAdvantage} />}
              {deltaSkyMiles && <Detail label="Delta SkyMiles" value={deltaSkyMiles} />}
              {jetBlueTrueBlue && <Detail label="JetBlue TrueBlue" value={jetBlueTrueBlue} />}
              {southwestRapidRewards && (
                <Detail label="Southwest Rapid Rewards" value={southwestRapidRewards} />
              )}
              {unitedMileagePlus && (
                <Detail label="United Mileage Plus" value={unitedMileagePlus} />
              )}
              {virginAmericaElevate && (
                <Detail label="Virgin America Elevate" value={virginAmericaElevate} />
              )}
              {westJetRewards && <Detail label="WestJet Rewards" value={westJetRewards} />}
              {otherAirlineRewards && (
                <Detail label="Other Airline Rewards" value={otherAirlineRewards} />
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              sx={{
                backgroundColor: '#EEEEEE',
              }}
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="special-content"
              id="special-header"
            >
              <Typography>Special Requests</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Detail label="Seating Preference" value={seatingPreference} />
              {adaNeeds && <Detail label="ADA Needs" value={adaNeeds} />}
              {additionalInfo && <Detail label="Additional Info" value={additionalInfo} />}
              {dietaryNeeds && <Detail label="Dietary Needs" value={dietaryNeeds} />}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>

      <ItrsTravelConfirmSection
        theme={theme}
        travelConfirmation={travelConfirmation}
        travelRequestId={item.id}
      />
      <Box sx={{ minHeight: 24 }}></Box>
    </CmsPage>
  );
};

export default ItrsTravelConfirmationPage;
