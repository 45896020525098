import { useCallback, useMemo, useEffect, useState } from 'react';
import useSavedPageState from '../../cmsPage/hooks/useSavedPageState';
import { findDefaultSort } from '../../itemTable/itemTableUtils';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { isEmpty } from 'lodash';
export const useListLoaderParams = (
  pageId,
  pageSize = 25,
  columns,
  additionalLoaderParams = {},
  parentItemId
) => {
  const savedLoaderParams = useSavedPageState(pageId);
  const restoreState = useMemo(() => {
    const expiresString = sessionStorage.getItem(`restore-state-${pageId}`);
    return expiresString ? moment.unix(Number.parseInt(expiresString)).isAfter(moment()) : false;
  }, [pageId]);
  const defaultSort = useMemo(() => findDefaultSort(columns), [columns]);

  const defaultLoaderParams = useMemo(() => {
    let result;
    if (restoreState) result = savedLoaderParams;
    return result;
  }, [restoreState, savedLoaderParams]);

  const [loaderParams, setLoaderParams] = useState(
    defaultLoaderParams || {
      limit: pageSize,
      offset: 0,
      search: '',
      sort: defaultSort,
      ...additionalLoaderParams,
      additionalFieldsSearch: {},
      parentItemId,
    }
  );

  useEffect(() => {
    if (isEmpty(additionalLoaderParams)) return;
    setLoaderParams((prevParams) => {
      if (!isEqual(prevParams.additionalLoaderParams, additionalLoaderParams)) {
        return { ...prevParams, ...additionalLoaderParams };
      }
      return prevParams;
    });
  }, [additionalLoaderParams]);

  const onSearchChange = useCallback(
    (search, additionalFieldsSearch) => {
      if (
        search !== loaderParams?.search ||
        !isEqual(additionalFieldsSearch, loaderParams?.additionalFieldsSearch)
      ) {
        setLoaderParams({ ...loaderParams, search, additionalFieldsSearch, offset: 0 });
      }
    },
    [loaderParams, setLoaderParams]
  );
  const onPageSelected = useCallback(
    (page) => {
      const currentPage = loaderParams.offset / loaderParams.limit;
      if (page !== currentPage + 1) {
        const offset = (page - 1) * loaderParams.limit;
        setLoaderParams({ ...loaderParams, offset });
      }
    },
    [loaderParams, setLoaderParams]
  );
  const onSortChange = useCallback(
    (sort) => {
      if (sort !== loaderParams.sort) setLoaderParams({ ...loaderParams, offset: 0, sort });
    },
    [loaderParams, setLoaderParams]
  );
  return useMemo(
    () => ({
      loaderParams,
      onSearchChange,
      onPageSelected,
      onSortChange,
    }),
    [loaderParams, onSearchChange, onPageSelected, onSortChange]
  );
};
