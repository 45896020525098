import Delete from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import ItemTable from '../../shared/itemTable/components/ItemTable';
import useArray from '../../shared/util/hooks/useArray';
import useSessionDetails from '../hooks/useSessionDetails';
import useAdminPrivileges from '../../shared/util/hooks/useAdminPrivileges';
import GenericTextInput from '../../shared/genericInputs/GenericTextInput';
import DenialModal from './DenialModal';
import { BackToCourses } from './BackButtons';

const Heading = ({ children, subheading = false, sx = {}, ...rest }) => (
  <Typography
    variant={subheading ? 'subtitle1' : 'h6'}
    sx={(theme) => ({
      backgroundColor: subheading ? theme.palette.bootstrap.grey : theme.palette.bootstrap.blue,
      color: 'white',
      padding: theme.spacing(1, 1),
      ...sx,
    })}
    {...rest}
  >
    {children}
  </Typography>
);

const Detail = ({ label, value }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
    <Box>
      <Typography variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
        {`${label}:`}
      </Typography>
    </Box>
    <Box sx={{ pl: '1rem' }}>
      <Typography variant="body1" gutterBottom>
        {value}
      </Typography>
    </Box>
  </Box>
);

const ItrsSessionViewPage = ({ sessionId }) => {
  const approvalDescription = 'Are you sure you want to approve this training request?';
  const denialDescription =
    'Are you sure you want to deny this training request? if so, enter a reason for denial.';
  const theme = useTheme();
  const [unionSearchField, setUnionSearchField] = React.useState('');
  const [denialReason, setDenialReason] = React.useState('');
  const [appContext, setAppContext] = React.useState({});
  const [approvalStatus, setApprovalStatus] = React.useState('');
  const { hasPrivilege } = useAdminPrivileges();
  const [openModal, setOpenModal] = React.useState(false);
  const {
    editSession,
    deleteSession,
    handleApproval,
    handleDeny,
    session,
    course,
    dateTitle,
    fullRange,
    enrollmentColumns,
    trainingRequestsColumns,
    attendeesColumns,
    enrollments,
    trainingRequests,
    instructors,
    students,
    viewTravelReq,
  } = useSessionDetails(sessionId, hasPrivilege, unionSearchField);
  const hasTrainingPrivileges = hasPrivilege('itrs.training');
  const hasTravelPrivileges = hasPrivilege('itrs.travel');
  const openApprovalAction = {
    label: `Approve Request`,
    icon: 'fa-solid fa-circle-check',
    disabled: () => !hasTrainingPrivileges,
    onClick: async (context) => {
      setApprovalStatus('APPROVED');
      setAppContext(context);
      setOpenModal(true);
    },
  };
  const openDenialAction = {
    label: `Deny Request`,
    icon: 'fa-solid fa-circle-minus',
    disabled: () => !hasTrainingPrivileges,
    onClick: async (context) => {
      setApprovalStatus('DENIED');
      setAppContext(context);
      setOpenModal(true);
    },
  };
  const trainingRequestItemActions = useArray(openApprovalAction, openDenialAction);
  const studentActions = useArray(viewTravelReq);

  const handleClose = () => {
    setOpenModal(false);
    setDenialReason('');
    setAppContext({});
  };

  const handleConfirm = () => {
    approvalStatus === 'APPROVED'
      ? handleApproval(appContext)
      : handleDeny(appContext, denialReason);
    setOpenModal(false);
    setDenialReason('');
    setAppContext({});
  };

  return (
    <CmsPage>
      <Box sx={{ padding: theme.spacing(0) }}>
        <BackToCourses />
        <Box sx={{ display: 'flex', alignItems: 'center', my: theme.spacing(2) }}>
          <div>
            <Typography variant="h4" component="h1" sx={{ flexGrow: 1 }}>
              {dateTitle}
            </Typography>
          </div>
          {hasTrainingPrivileges && (
            <>
              <IconButton sx={{ color: theme.palette.primary.main }} onClick={editSession}>
                <EditIcon />
              </IconButton>
              <IconButton sx={{ color: theme.palette.primary.main }} onClick={deleteSession}>
                <Delete />
              </IconButton>
            </>
          )}
        </Box>
        <Detail label="Course" value={`${course.name}`} />
        <Detail label="Start/End Date" value={fullRange} />
        <Detail label="Status" value={session.statusLabel} />
        <Box sx={{ my: theme.spacing(3) }}>
          <Heading gutterBottom sx={{ mb: theme.spacing(1) }}>
            Enrollment Overview
          </Heading>
          <ItemTable clientSort columns={enrollmentColumns} items={enrollments} />
        </Box>

        {hasTrainingPrivileges && (
          <Box sx={{ marginBottom: theme.spacing(3) }}>
            <Heading gutterBottom sx={{ mb: theme.spacing(1) }}>
              Training Requests
            </Heading>
            <ItemTable
              itemActions={trainingRequestItemActions}
              clientSort
              columns={trainingRequestsColumns}
              items={trainingRequests}
            />
          </Box>
        )}

        <Box sx={{ marginBottom: theme.spacing(3) }}>
          <Heading>Attendees</Heading>
          <Heading subheading gutterBottom sx={{ mb: theme.spacing(1) }}>
            Instructors
          </Heading>
          <ItemTable clientSort columns={attendeesColumns} items={instructors} />
          <Heading subheading gutterBottom sx={{ mb: theme.spacing(1) }}>
            Students
          </Heading>
          {hasTravelPrivileges && (
            <Box sx={{ marginBottom: theme.spacing(3) }}>
              <GenericTextInput
                className="form-control"
                label=""
                value={unionSearchField}
                fieldWidth="short"
                onChange={(e) => setUnionSearchField(e?.target?.value)}
                variant="outlined"
              />
              <div style={{ fontSize: '9px', paddingLeft: '2.5rem' }}>
                Search by Name or Local Union
              </div>
            </Box>
          )}
          <ItemTable
            clientSort
            columns={attendeesColumns}
            items={students}
            itemActions={studentActions}
          />
        </Box>
      </Box>

      <DenialModal
        open={openModal}
        handleClose={handleClose}
        title={approvalStatus === 'APPROVED' ? 'Approve Request?' : 'Deny Request?'}
        description={approvalStatus === 'APPROVED' ? approvalDescription : denialDescription}
        hasInput={approvalStatus === 'APPROVED' ? false : true}
        inputLabel={approvalStatus === 'APPROVED' ? '' : 'Reason for Denial'}
        actionTitle={approvalStatus === 'APPROVED' ? 'Approve Request' : 'Deny Request'}
        handleSave={handleConfirm}
        handleChange={(e) => setDenialReason(e.target.value)}
      />
      <Box sx={{ minHeight: 24 }}></Box>
    </CmsPage>
  );
};

export default ItrsSessionViewPage;
