import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getSessionDetails } from '../api/sessionApi';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { ItrsCourseConstants, ItrsSessionConstants } from '../definitions';
import { navigateToPluginPage } from '../../store/rootReducer';
import sessionApi from '../api/sessionApi';
import logger from '../../logger';

const enrollmentColumns = [
  { label: 'Attendee Type', valueField: 'attendeeType', sortable: true },
  { label: 'Pending Requests', valueField: 'pendingRequests', sortable: true },
  { label: 'Approved', valueField: 'approved', sortable: true },
  { label: 'Total Enrolled', valueField: 'totalEnrolled', sortable: true },
  { label: 'Max. Seats', valueField: 'maxSeats', sortable: true },
  { label: 'Travel Requests Submitted', valueField: 'travelRequestsSubmitted', sortable: true },
  { label: 'Travel Confirmed', valueField: 'travelConfirmed', sortable: true },
];

const trainingRequestsColumns = [
  { label: 'Name', valueField: 'name', sortable: true },
  { label: 'Local Union', valueField: 'localUnion', sortable: true },
  { label: 'Date Submitted', valueField: 'dateSubmitted', sortable: true },
  { label: 'Attendee Type', valueField: 'attendeeType', sortable: true },
  { label: 'Status', valueField: 'status', sortable: true },
];

export default function useSessionDetails(id, hasPrivilege, unionSearchField) {
  const queryClient = useQueryClient();
  const {
    data: {
      course = {},
      session = {},
      instructors = [],
      students = [],
      trainingRequests = [],
      enrollments = [],
    } = {},
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ['itrs-session-details', { id, unionSearchField }],
    queryFn: () => getSessionDetails(id, unionSearchField),
    enabled: !!id,
  });
  const dispatch = useDispatch();
  const hasTravelPrivileges = hasPrivilege('itrs.travel');
  const { startDate, endDate } = session;
  const { dateTitle, fullRange } = useMemo(() => {
    if (!startDate || !endDate) return { dateTile: '', fullRange: '' };
    const s = moment(startDate).utc();
    const e = moment(endDate).utc();
    let title = '';
    if (s.isSame(e, 'day')) {
      // Same day
      title = s.format('MMMM D, YYYY');
    } else if (s.isSame(e, 'month') && s.isSame(e, 'year')) {
      // Same month and year
      title = `${s.format('MMMM')} ${s.format('D')}-${e.format('D')}, ${s.format('YYYY')}`;
    } else if (s.isSame(e, 'year')) {
      // Same year, different months
      title = `${s.format('MMMM D')} - ${e.format('MMMM D')}, ${s.format('YYYY')}`;
    } else {
      // Different years
      title = `${s.format('MMMM D, YYYY')} - ${e.format('MMMM D, YYYY')}`;
    }
    const fullRange = `${s.format('MM/DD/YYYY')} - ${e.format('MM/DD/YYYY')}`;
    return { dateTitle: title, fullRange };
  }, [startDate, endDate]);

  const editSession = useCallback(() => {
    dispatch(
      navigateToPluginPage([
        ItrsSessionConstants.pluginId,
        ItrsSessionConstants.editorPageId,
        course.id,
        id,
      ])
    );
  }, [dispatch, id, course.id]);

  const deleteSession = useCallback(async () => {
    await sessionApi.removeRecord(id);
    dispatch(
      navigateToPluginPage([ItrsCourseConstants.pluginId, ItrsCourseConstants.defaultPageId])
    );
  }, [dispatch]);

  const handleApproval = useCallback(
    async (context) => {
      try {
        const approvals = context.approvals.map((approval) => ({
          id: approval.id,
          status: approval.status,
        }));
        const approvalId = approvals.find((approval) => approval.status === 'PENDING').id;

        await sessionApi.approveTrainingRequest(approvalId, 'APPROVED');
        // give time for workflow to process
        await new Promise((resolve) => setTimeout(resolve, 2000));
        queryClient.invalidateQueries({ queryKey: ['itrs-session-details', { id }] });
      } catch (error) {
        logger.error(error);
      }
    },
    [id, queryClient]
  );

  const handleDeny = useCallback(
    async (context, denialReason) => {
      try {
        const approvals = context.approvals.map((approval) => ({
          id: approval.id,
          status: approval.status,
        }));
        const approvalId = approvals.find((approval) => approval.status === 'PENDING').id;
        await sessionApi.approveTrainingRequest(approvalId, 'DENIED', denialReason);
        // give time for workflow to process
        await new Promise((resolve) => setTimeout(resolve, 2000));
        queryClient.invalidateQueries({ queryKey: ['itrs-session-details', { id }] });
      } catch (error) {
        logger.error(error);
      }
    },
    [id]
  );

  const viewTravelReq = useMemo(
    () => ({
      icon: 'fa fa-search',
      label: `View Travel Request`,
      disabled: (context) => !context.travelRequest,
      onClick: async (context) => {
        try {
          if (!context.travelRequest) throw new Error('No travel request found');
          dispatch(
            navigateToPluginPage([
              ItrsSessionConstants.pluginId,
              ItrsSessionConstants.travelConfirmationPageId,
              context.travelRequest.id,
            ])
          );
        } catch (error) {
          logger.error(error);
        }
      },
    }),
    [id]
  );

  const attendeesColumns = [
    { label: 'Name', valueField: 'name', sortable: true },
    { label: 'Local Union', valueField: 'localUnion', sortable: true },
    {
      label: 'Status',
      valueField: 'status',
      sortable: true,
      renderer: (item) => {
        const req = item.travelRequest;
        const notSubmitted = !req;
        const submitted =
          req && (!req.travelConfirmation || req.travelConfirmation.status !== 'confirmed');
        const confirmed = req?.travelConfirmation?.status === 'confirmed';
        if (!hasTravelPrivileges) {
          if (confirmed) return 'Approved';
          return 'Not Approved';
        }
        return (
          <span>
            {notSubmitted && (
              <span>
                Travel Request NOT Submitted
                <i style={{ marginLeft: '0.5em', color: '#DC3545' }} className="fa fa-plane-up"></i>
              </span>
            )}
            {submitted && (
              <span>
                Travel Request Submitted
                <i style={{ marginLeft: '0.5em', color: '#FFC107' }} className="fa fa-plane-up"></i>
              </span>
            )}
            {confirmed && (
              <span>
                Travel Request Confirmed
                <i style={{ marginLeft: '0.5em', color: '#28A745' }} className="fa fa-plane-up"></i>
              </span>
            )}
          </span>
        );
      },
    },
  ];

  return {
    editSession,
    deleteSession,
    handleApproval,
    handleDeny,
    session,
    course,
    dateTitle,
    fullRange,
    enrollmentColumns,
    trainingRequestsColumns,
    attendeesColumns,
    enrollments,
    trainingRequests,
    instructors,
    students,
    isLoading,
    isError,
    error,
    viewTravelReq,
  };
}
