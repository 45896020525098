import get from 'lodash/get';
import rfc from 'react-fast-compare';
import { useSelector } from 'react-redux';
import { reducerName } from './rootReducer';

const getters = (st, fieldName = '') => ({
  gs: (path = '', def = undefined) =>
    get(st, `${reducerName}${fieldName ? `.${fieldName}` : ''}${path ? `.${path}` : path}`, def),
});
const us = (cb) =>
  useSelector((state) => {
    const { gs } = getters(state);
    return cb(gs, state);
  }, rfc);

export const selectGrowls = () => us((gs) => gs('growls', {}));

export const selectCmsBaseUrl = () =>
  us((gs) => {
    const base = window.location.origin || gs(`config.cmsBaseUrl`, '');
    return base?.endsWith('/') ? base : `${base}/`;
  });

export const selectGroupGrowls = (groupId) => us((gs) => gs(`growls.${groupId}`, []));

export const selectNav = () => us((gs) => gs('navigation', {}));

export const selectConfig = () => us((gs) => gs('config', {}));
export const selectMapConfig = () => us((gs) => gs('config.mapConfig', {}));
export const selectIsServiceConfigured = (serviceName) =>
  us((gs) => gs('config.plugins', [])?.includes(serviceName));

export const selectAppUserSettings = (state) => {
  const { allowAppUsersImport, denyAdditionalCustomFields, allowAppUsersCustomAuth, role } =
    state?.root?.config || {};
  return {
    allowAppUsersImport,
    allowAppUsersCustomAuth,
    requireEmailForImport: role !== 'super_admin',
    allowAdditionalFields: !denyAdditionalCustomFields && role === 'super_admin',
  };
};
