import React from 'react';
import BreadcrumbLink from '../../shared/actionButton/components/BreadcrumbLink';
import { ItrsCourseConstants } from '../definitions';

const { pluginId, coursePageId, defaultPageId, defaultPageName } = ItrsCourseConstants;

export const BackToDashboard = () => {
  return (
    <BreadcrumbLink
      pluginId={pluginId}
      parentPageId={defaultPageId}
      parentPageName={defaultPageName}
      parentPageTitle={'Dashboard'}
    />
  );
};

export const BackToCourses = () => {
  return (
    <BreadcrumbLink
      pluginId={pluginId}
      parentPageId={coursePageId}
      parentPageName={coursePageId}
      parentPageTitle={'Courses'}
    />
  );
};

export const BackToMailLog = () => {
  return (
    <BreadcrumbLink
      pluginId={pluginId}
      parentPageId={ItrsCourseConstants.mailLogPageId}
      parentPageName={ItrsCourseConstants.mailLogPageId}
      parentPageTitle={'Mail Log'}
    />
  );
};
