import React from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useArray from '../../shared/util/hooks/useArray';
import { ItrsCourseConstants } from '../definitions';
import useItrsMailLogActions from '../hooks/useItrsMailLogActions';
import useMailLogConfigurations from '../hooks/useMailLogConfigurations';
import { BackToDashboard } from './BackButtons';
import SessionTable from './SessionTable';

const TableWrapper = ({ parentItem, reloadParent }) => {
  return <SessionTable courseId={parentItem.id} reloadParent={reloadParent} hideOptions={true} />;
};

const ItrsMailLogPage = () => {
  const { mailLogPageId, typeName } = ItrsCourseConstants;
  const { view } = useItrsMailLogActions(ItrsCourseConstants);
  const { columns, api } = useMailLogConfigurations();
  const itemActions = useArray(view);

  return (
    <>
      <BackToDashboard />
      <CmsPage title={'Mail log'}>
        <CmsItemListSection
          pageId={mailLogPageId}
          typeName={typeName}
          toolbarActions={[]}
          itemActions={itemActions}
          columns={columns}
          itemApi={api}
          NestedComponent={TableWrapper}
        />
      </CmsPage>
    </>
  );
};

export default ItrsMailLogPage;
