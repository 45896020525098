import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import log from '../../logger';
import { navigateToPluginPage } from '../../store/rootReducer';
import { errorGrowl } from '../../store/rootThunks';
import itrsApi from '../api/itrsApi';
import sessionApi from '../api/sessionApi';
import { ItrsCourseConstants, ItrsSessionConstants } from '../definitions';

const { typeName, pluginId, viewPageId, editorPageId, coursePageId, defaultPageId } =
  ItrsCourseConstants;

export default function useItrsActions(reloadParent) {
  const dispatch = useDispatch();

  const add = useMemo(
    () => ({
      label: `+ Add Course`,
      onClick: async () => {
        try {
          const newCourse = await itrsApi.loadRecord('new');
          dispatch(navigateToPluginPage([pluginId, editorPageId, newCourse.id || 'new']));
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: coursePageId, props: 'Failed to add.' }));
        }
      },
    }),
    [typeName, dispatch, pluginId]
  );

  const viewMailLog = useMemo(
    () => ({
      label: `View Mail Log`,
      onClick: async () => {
        try {
          dispatch(
            navigateToPluginPage([ItrsCourseConstants.pluginId, ItrsCourseConstants.mailLogPageId])
          );
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: defaultPageId, props: 'Failed to open mail log.' }));
        }
      },
    }),
    [typeName, dispatch, pluginId, defaultPageId]
  );

  const viewTravelRequests = useMemo(
    () => ({
      label: `View Travel Requests`,
      onClick: async () => {
        try {
          dispatch(
            navigateToPluginPage([
              ItrsSessionConstants.pluginId,
              ItrsSessionConstants.travelRequestsPageId,
            ])
          );
        } catch (e) {
          log.error(e);
          dispatch(
            errorGrowl({ groupId: defaultPageId, props: 'Failed to open travel requests page.' })
          );
        }
      },
    }),
    [typeName, dispatch, pluginId, defaultPageId]
  );

  const addSession = useMemo(
    () => ({
      label: `+ Add Session`,
      onClick: async (context) => {
        try {
          const newSession = await sessionApi.loadRecord('new');
          const courseId = context?.loaderParams?.courseId || context.courseId || context.id;
          dispatch(
            navigateToPluginPage([
              ItrsSessionConstants.pluginId,
              ItrsSessionConstants.editorPageId,
              courseId,
              newSession.id || 'new',
            ])
          );
        } catch (e) {
          log.error(e);
          dispatch(
            errorGrowl({ groupId: ItrsSessionConstants.editorPageId, props: 'Failed to add.' })
          );
        }
      },
    }),
    [typeName, dispatch]
  );

  const edit = useMemo(
    () => ({
      icon: 'fa-solid fa-pencil',
      label: 'Edit Course',
      onClick: async (context) => {
        try {
          const foundCourse = await itrsApi.loadRecord(context.id);
          dispatch(navigateToPluginPage([pluginId, editorPageId, foundCourse.id]));
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: coursePageId, props: 'Failed to add.' }));
        }
      },
    }),
    [dispatch, pluginId]
  );

  const editSession = useMemo(
    () => ({
      icon: 'fa-solid fa-pencil',
      label: 'Edit',
      onClick: async (context) => {
        try {
          const foundSession = await sessionApi.loadRecord(context.id);
          dispatch(
            navigateToPluginPage([
              ItrsSessionConstants.pluginId,
              ItrsSessionConstants.editorPageId,
              foundSession.courseId,
              foundSession.id,
            ])
          );
        } catch (e) {
          log.error(e);
          dispatch(
            errorGrowl({ groupId: ItrsSessionConstants.editorPageId, props: 'Failed to add.' })
          );
        }
      },
    }),
    [dispatch, pluginId]
  );

  const view = useMemo(
    () => ({
      icon: 'fa-solid fa-list-tree',
      label: 'View Course',
      onClick: (context) => {
        dispatch(navigateToPluginPage([pluginId, viewPageId, context.id]));
      },
    }),
    [dispatch, pluginId]
  );

  const viewSession = useMemo(
    () => ({
      icon: 'fa-solid fa-list-tree',
      label: 'View Session',
      onClick: (context) => {
        dispatch(navigateToPluginPage([pluginId, ItrsSessionConstants.viewPageId, context.id]));
      },
    }),
    [dispatch, pluginId]
  );

  const remove = useMemo(
    () => ({
      icon: 'fa-solid fa-trash',
      label: 'Remove Course',
      disabled: (context) => !!context.sessionCount,
      onClick: async (context, { reload }) => {
        try {
          await itrsApi.removeRecord(context.id);
          await reload();
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: coursePageId, props: 'Failed to remove course.' }));
        }
      },
    }),
    [dispatch, pluginId]
  );

  const removeSession = useMemo(
    () => ({
      icon: 'fa-solid fa-trash',
      label: 'Remove Session',
      onClick: async (context, { reload }) => {
        try {
          await sessionApi.removeRecord(context.id);
          await reload();
          if (typeof reloadParent === 'function') await reloadParent();
        } catch (e) {
          log.error(e);
          dispatch(errorGrowl({ groupId: coursePageId, props: 'Failed to remove session.' }));
        }
      },
    }),
    [dispatch, pluginId, coursePageId, reloadParent]
  );

  return {
    add,
    edit,
    view,
    remove,
    addSession,
    editSession,
    viewSession,
    removeSession,
    viewMailLog,
    viewTravelRequests,
  };
}
