import moment from 'moment';
import React from 'react';
import api from '../api/sessionApi';
import CollectionLink from '../components/CollectionLink';
const columns = [
  {
    label: 'Session Name',
    valueField: 'name',
    sortable: true,
    initialSort: 'ASC',
  },
  {
    label: 'Start/End Dates',
    valueField: 'startDate',
    sortable: true,
    renderer: (item) =>
      `${moment.utc(item.startDate).format('MMMM DD, YYYY')} - ${moment
        .utc(item.endDate)
        .format('MMMM DD, YYYY')}`,
  },
  {
    label: 'Status',
    valueField: 'status',
    sortable: true,
  },
  {
    label: 'Collection',
    valueField: 'collectionId',
    sortable: false,
    renderer: ({ collectionId }) => <CollectionLink collectionId={collectionId} />,
  },
  {
    label: 'Submitted / Approved',
    valueField: 'submittedApprovals',
    sortable: false,
    renderer: (item) => {
      const { approved, total } = item.submittedApprovals;
      return (
        <>
          {total || '0'} / {approved}
        </>
      );
    },
  },
];

export default function useSessionConfigurations() {
  return { columns, api };
}
