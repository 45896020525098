import React, { useState, useMemo, useCallback } from 'react';
import { getColumnSortOrder } from '../itemTableUtils';
import ItemTableHeaderCell from './ItemTableHeaderCell';
import ItemTableRow from './ItemTableRow';
import PaginationControl from './PaginationControl';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ItemTable = ({
  columns,
  itemActions,
  reload,
  itemSort = (i) => i,
  items = [],
  onPageSelected,
  onSortChange,
  pagination,
  showTotal,
  sort,
  tableFooter,
  tableRowCustomClass = () => '',
  NestedComponent = null,
  style = {},
  clientSort = false,
  loaderParams,
}) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const [clientSortConfig, setClientSortConfig] = useState({ key: null, direction: 'ASC' });

  const onColumnClick = useCallback(
    (column) => {
      const sortField = column.valueField;
      if (clientSort) {
        setClientSortConfig((prevConfig) => ({
          key: sortField,
          direction:
            prevConfig.key === sortField && prevConfig.direction === 'ASC' ? 'DESC' : 'ASC',
        }));
      } else {
        const order = getColumnSortOrder(column, sort);
        const newOrder = order === 'ASC' ? 'DESC' : 'ASC';
        onSortChange({ order: newOrder, sortField: sortField });
      }
    },
    [clientSort, sort, onSortChange]
  );

  const isNumeric = (value) => {
    return !Number.isNaN(Number.parseFloat(value)) && Number.isFinite(value);
  };

  const compareValues = (a, b) => {
    if (isNumeric(a) && isNumeric(b)) {
      return Number.parseFloat(a) - Number.parseFloat(b);
    }
    return String(a).localeCompare(String(b), undefined, { numeric: true, sensitivity: 'base' });
  };

  const sortedItems = useMemo(() => {
    if (!clientSort) return itemSort(items);

    return [...items].sort((a, b) => {
      if (clientSortConfig.key === null) return 0;
      const aValue = a[clientSortConfig.key];
      const bValue = b[clientSortConfig.key];
      const comparison = compareValues(aValue, bValue);
      return clientSortConfig.direction === 'ASC' ? comparison : -comparison;
    });
  }, [clientSort, items, itemSort, clientSortConfig]);

  const { offset = 0, numRecords: total = 0, limit = 1 } = pagination || {};
  const min = offset + 1;
  const max = Math.min(total, min + limit - 1);

  const isExpanded = (index) => index === expandedRow;

  return (
    <div style={style}>
      <table className="table table-striped no-margin" style={{ marginBottom: '0px !important' }}>
        <thead>
          <tr>
            {NestedComponent && <ItemTableHeaderCell sortOrder={null} column={{}} />}
            {columns.map((column, index) => (
              <ItemTableHeaderCell
                key={index}
                column={column}
                onClick={() => onColumnClick(column)}
                sortOrder={
                  clientSort
                    ? clientSortConfig.key === column.valueField
                      ? clientSortConfig.direction
                      : 'NONE'
                    : getColumnSortOrder(column, sort)
                }
              />
            ))}
            {itemActions?.length > 0 && <th />}
          </tr>
        </thead>
        {sortedItems.length > 0 ? (
          <tbody>
            {sortedItems.map((item, i) => (
              <React.Fragment key={i}>
                <tr className={tableRowCustomClass(item)}>
                  {NestedComponent && (
                    <td
                      onClick={() => {
                        i === expandedRow ? setExpandedRow(null) : setExpandedRow(i);
                      }}
                    >
                      {isExpanded(i) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </td>
                  )}
                  <ItemTableRow item={item} columns={columns} itemActions={itemActions} />
                </tr>
                {isExpanded(i) && (
                  <tr>
                    <td
                      colSpan={columns.length + (NestedComponent ? 1 : 0) + (itemActions ? 1 : 0)}
                    >
                      <NestedComponent
                        parentItem={item}
                        reloadParent={reload}
                        additionalFieldsSearch={loaderParams?.additionalFieldsSearch || {}}
                      />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td
                className="table-message"
                colSpan={columns.length + (NestedComponent ? 1 : 0) + (itemActions ? 1 : 0)}
              >
                There are no items that match your criteria.
              </td>
            </tr>
          </tbody>
        )}
      </table>
      {!clientSort && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'flex-start',
            marginTop: '.5rem',
          }}
        >
          <div style={{ flex: 1, textAlign: 'left' }}>{tableFooter?.left || ''}</div>
          <div
            style={{
              flexGrow: 0,
              flexShrink: 1,
              flexBasis: 'auto',
              textAlign: 'center',
              margin: '0 auto',
            }}
          >
            {showTotal && (
              <span style={{ paddingTop: '0.75em', display: 'inline-block' }}>
                {`Showing ${min} - ${max} of `} <strong>{total}</strong>
              </span>
            )}
            <PaginationControl {...pagination} onPageSelected={onPageSelected} />
          </div>
          <div style={{ flex: 1, textAlign: 'right' }}>{tableFooter?.right || ''}</div>
        </div>
      )}
    </div>
  );
};

export default ItemTable;
