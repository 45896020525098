import React, { useEffect, useState } from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useArray from '../../shared/util/hooks/useArray';
import manageGroupsEditApi from '../api/manageGroupsEditApi';
import { ManageGroupsConstants } from '../definitions';
import useManageGroupActions from '../hooks/useManageGroupActions';
import useManageUserActions from '../hooks/useManageUserActions';
import CmsPageSectionFooter from '../../shared/cmsPage/components/CmsPageSectionFooter';
import useRefreshActionCallback from '../../shared/cmsPage/hooks/useRefreshActionCallback';

const ManageGroupsEditMembers = (props) => {
  const { groupId, groupName, groupKey, groupCanAssign } = props;
  const canAssign = groupCanAssign === 'true';
  const [additiontalSearchFieldsValue, setAdditiontalSearchFieldsValue] = useState(true);
  const [checkedUsers, setCheckedUsers] = useState({});
  const passedProps = {
    groupId,
    groupName,
    groupKey,
    additiontalSearchFieldsValue,
    checkedUsers,
    groupCanAssign: canAssign,
  };

  const handleCheckUser = (e) => {
    setCheckedUsers({ ...checkedUsers, [e.target.value]: e.target.checked });
  };

  useEffect(() => {
    setCheckedUsers({});
  }, [additiontalSearchFieldsValue]);

  const { managePageName, typeName } = ManageGroupsConstants;
  const { hiddenButton, refreshCallback } = useRefreshActionCallback(managePageName);

  const { exportCsv } = useManageGroupActions(ManageGroupsConstants, groupId);
  const actions = useManageUserActions(passedProps, refreshCallback);
  const pageSize = 10;

  const columns = [
    {
      label: 'User Select',
      renderer: (item) => {
        const isChecked = checkedUsers[item.id.toString()] || false;
        return (
          <input
            value={item.id.toString()}
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckUser}
          />
        );
      },
      sortable: false,
      valueField: 'id',
    },
    { initialSort: 'ASC', label: 'Last Name', sortable: true, valueField: 'lastName' },
    { initialSort: 'ASC', label: 'First Name', sortable: true, valueField: 'firstName' },
    { initialSort: 'ASC', label: 'Email', sortable: true, valueField: 'email' },
  ];

  const toolbarActions = useArray(exportCsv, hiddenButton);
  const itemActions = useArray();

  return (
    <>
      <CmsPage title={`Manage Group - ${groupName}`}>
        <CmsItemListSection
          showSelect={false}
          showTotal={true}
          pageSize={pageSize}
          pageId={managePageName}
          typeName={typeName}
          toolbarActions={toolbarActions}
          itemActions={itemActions}
          columns={columns}
          itemApi={manageGroupsEditApi}
          additionalLoaderParams={{ groupId, groupName, groupKey }}
          onAdditionalSearchFieldsChange={(fields) => {
            if (fields.inGroup) {
              setAdditiontalSearchFieldsValue(false);
            } else {
              setAdditiontalSearchFieldsValue(true);
            }
          }}
          additionalSearchFields={[
            {
              key: 'inGroup',
              noSelectText: 'Show Members',
              placeholder: 'Members Shown',
              type: 'select',
              options: [{ label: 'Show Non-Members', value: 'false' }],
            },
          ]}
          searchPlaceholder={'Search by User'}
        />
        <CmsPageSectionFooter actions={actions} />
      </CmsPage>
    </>
  );
};

export default ManageGroupsEditMembers;
