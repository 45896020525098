import React from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import CmsPage from '../../shared/cmsPage/components/CmsPage';
import useArray from '../../shared/util/hooks/useArray';
import { ItrsCourseConstants } from '../definitions';
import useCourseConfigurations from '../hooks/useCourseConfigurations';
import useItrsActions from '../hooks/useItrsActions';
import { BackToDashboard } from './BackButtons';
import SessionTable from './SessionTable';

const TableWrapper = ({ parentItem, reloadParent, additionalFieldsSearch }) => {
  return (
    <SessionTable
      courseId={parentItem.id}
      additionalFieldsSearch={additionalFieldsSearch}
      reloadParent={reloadParent}
      hidePastSessions={!!additionalFieldsSearch?.hidePastSessions}
      hideOptions={true}
    />
  );
};

const ItrsCourseListPage = () => {
  const { coursePageId, typeName } = ItrsCourseConstants;
  const { add, edit, view, remove, addSession, viewMailLog, viewTravelRequests } =
    useItrsActions(ItrsCourseConstants);
  const { columns, api } = useCourseConfigurations();
  const toolbarActions = useArray(viewTravelRequests, viewMailLog, add);
  const itemActions = useArray(addSession, edit, view, remove);

  return (
    <>
      <BackToDashboard />
      <CmsPage title={'Courses'}>
        <CmsItemListSection
          pageId={coursePageId}
          typeName={typeName}
          toolbarActions={toolbarActions}
          itemActions={itemActions}
          columns={columns}
          itemApi={api}
          NestedComponent={TableWrapper}
          additionalSearchFields={[
            { key: 'hidePastSessions', placeholder: 'Hide Past Sessions', type: 'switch' },
          ]}
        />
      </CmsPage>
    </>
  );
};

export default ItrsCourseListPage;
