import React, { useMemo } from 'react';
import CmsItemListSection from '../../shared/cmsPage/components/CmsItemListSection';
import useArray from '../../shared/util/hooks/useArray';
import { ItrsCourseConstants } from '../definitions';
import useItrsActions from '../hooks/useItrsActions';
import useSessionConfigurations from '../hooks/useSessionConfigurations';

const SessionTable = ({ courseId, hideOptions, reloadParent, hidePastSessions = false }) => {
  const { coursePageId, typeName } = ItrsCourseConstants;
  const { addSession, editSession, viewSession, removeSession } = useItrsActions(reloadParent);
  const { columns, api } = useSessionConfigurations();
  const toolbarActions = useArray(addSession);
  const itemActions = useArray(editSession, viewSession, removeSession);
  const additionalLoaderParams = useMemo(
    () => ({ courseId, hidePastSessions }),
    [courseId, hidePastSessions]
  );
  return (
    <CmsItemListSection
      pageId={coursePageId}
      typeName={typeName}
      toolbarActions={hideOptions ? [] : toolbarActions}
      itemActions={itemActions}
      columns={columns}
      itemApi={api}
      additionalLoaderParams={additionalLoaderParams}
      showSearchBar={!hideOptions}
    />
  );
};

export default SessionTable;
