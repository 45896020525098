import React, { useEffect } from 'react';
import FormCheckBox from '../../shared/form/components/FormCheckBox';
import FormDateOnly from '../../shared/form/components/FormDateOnly';
import FormDate from '../../shared/form/components/FormDate';
import FormInput from '../../shared/form/components/FormInput';
import RadioButtonGroup from '../../shared/form/components/FormRadioButtonGroup';
import FormWysiwyg from '../../shared/form/components/FormWysiwyg';
import FormCheckBoxGroup from '../../shared/form/components/FormCheckBoxGroup';
import MultiSelect from '../../shared/form/components/MultiSelect';
import api from '../api/itrsApi';
import Divider from '@mui/material/Divider';
import { toNumber } from 'lodash';
import Box from '@mui/material/Box';

const SessionMulti = ({ placeholder, form, label, req, isSingleSelect = true, ...props }) => (
  <MultiSelect
    label={label}
    fieldWidth={500}
    fontWeight={500}
    labelColor="#949494"
    formContext={form}
    placeholder={placeholder || `Select ${label}`}
    valueKey={'value'}
    labelKey="label"
    isSingleSelect={isSingleSelect}
    findValueInOptions={true}
    {...(req && { formFieldOptions: { required: `${label} Is Required` } })}
    {...props}
  />
);

const SessionDetailForm = ({ form }) => {
  const { watch, setValue, getValues, trigger } = form;

  const travelHorizons = watch('travelHorizons');

  const getArrivalStart = getValues('arrivalStartDate');
  const getArrivalEnd = getValues('arrivalEndDate');
  const getDepartStart = getValues('departureStartDate');
  const getDepartEnd = getValues('departureEndDate');

  const getArrivalStartDate = new Date(getArrivalStart);
  const getArrivalEndDate = new Date(getArrivalEnd);
  const getDepartStartDate = new Date(getDepartStart);
  const getDepartEndDate = new Date(getDepartEnd);

  function toDatetimeLocal(date) {
    if (!date) return '';
    const pad = (num) => String(num).padStart(2, '0');
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  useEffect(() => {
    if (travelHorizons && getArrivalStart && getArrivalEnd && getDepartStart && getDepartEnd) {
      setValue('arrivalStartDate', toDatetimeLocal(getArrivalStartDate));
      setValue('arrivalEndDate', toDatetimeLocal(getArrivalEndDate));
      setValue('departureStartDate', toDatetimeLocal(getDepartStartDate));
      setValue('departureEndDate', toDatetimeLocal(getDepartEndDate));
    } else if (
      travelHorizons &&
      !getArrivalStart &&
      !getArrivalEnd &&
      !getDepartStart &&
      !getDepartEnd
    ) {
      setValue('arrivalStartDate', '');
      setValue('arrivalEndDate', '');
      setValue('departureStartDate', '');
      setValue('departureEndDate', '');
    } else {
      setValue('arrivalStartDate', '');
      setValue('arrivalEndDate', '');
      setValue('departureStartDate', '');
      setValue('departureEndDate', '');
    }
  }, [travelHorizons]);

  useEffect(() => {
    if (travelHorizons) {
      trigger(['arrivalStartDate', 'arrivalEndDate', 'departureStartDate', 'departureEndDate']);
    }
  }, [travelHorizons]);

  const inputPropsConfig = {
    inputProps: {
      min: '0',
      sx: {
        '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
          background: 'transparent',
          border: 'none',
          opacity: 1,
          display: 'block',
        },
        MozAppearance: 'textfield',
      },
    },
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          padding: 10,
          fontWeight: 'bold',
          backgroundColor: '#1B76D2',
          color: 'white',
          marginBottom: 20,
        }}
      >
        <p style={{ marginBottom: 0, marginLeft: 10 }}>Session Details</p>
      </div>

      <RadioButtonGroup
        labelWeight={500}
        labelColor="#949494"
        name="status"
        type="radio"
        form={form}
        label="Session Status"
        options={[
          { value: 'draft', label: 'Draft' },
          { value: 'enrolling', label: 'Enrolling' },
          { value: 'active', label: 'Active' },
          { value: 'cut-off reached', label: 'Cut-Off Reached' },
          { value: 'complete', label: 'Complete' },
        ]}
        validation={{ required: 'Status is required.' }}
      />

      <Divider sx={{ my: 4 }} />

      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 30 }}>
        <div>
          <FormDateOnly
            label={`Start Date`}
            fieldName="startDate"
            formContext={form}
            fieldWidth="short"
            formFieldOptions={{
              required: 'Start date is required',
              validate: (startDate) => {
                const endDate = watch('endDate');
                if (startDate && endDate) {
                  const start = new Date(startDate);
                  const end = new Date(endDate);
                  if (end < start) {
                    return 'End date cannot be before start date';
                  }
                }
                return true;
              },
            }}
          />
        </div>

        <div style={{ marginLeft: 20 }}>
          <FormDateOnly
            label={`End Date`}
            fieldName="endDate"
            fieldWidth="short"
            formContext={form}
            formFieldOptions={{
              required: 'End date is required',
              validate: (endDate) => {
                const startDate = getValues('startDate');
                if (startDate && endDate < startDate) {
                  return 'End date cannot be before start date';
                }
                return true;
              },
            }}
          />
        </div>
      </div>

      <div style={{ display: 'flex', alignItems: 'end' }}>
        <FormInput
          label="Enrollment Cut-Off"
          fieldName="cutoffDays"
          fieldWidth="short"
          formContext={form}
          type="number"
          {...inputPropsConfig}
          formFieldOptions={{
            required: 'Enrollment Cutoff is required',
            validate: (v) => {
              if (toNumber(v) < 0) return 'Minimum value is 0';
              return true;
            },
          }}
          onBlur={() => trigger('cutoffDays')}
        />
        <p style={{ marginLeft: 10, fontWeight: 500 }}>days before start date of session</p>
      </div>

      <div style={{ marginTop: 10 }}>
        <FormCheckBox
          id={'travelhorizons'}
          label="Enable Travel Horizons"
          tooltipType="question"
          infoTooltip="Use this option to set travel arrival and departure dates for a session. If defined, attendee travel dates must be within horizon guidelines or an explanation and additional approval requirements will be required."
          fieldName={`travelHorizons`}
          formContext={form}
          preChecked={travelHorizons}
          type="checkbox"
        />
      </div>

      <div style={{ display: travelHorizons ? 'block' : 'none' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Box
            sx={{
              width: '34em',
              height: 220,
              borderRadius: 1,
              padding: 2,
              bgcolor: '#EEEEEE',
              marginRight: 4,
              marginBottom: 2,
            }}
          >
            <div>
              <div style={{ fontWeight: 700, marginBottom: 10 }}>Arrival Horizon</div>
              <FormDate
                label={`Start Date/Time`}
                fieldName="arrivalStartDate"
                fieldWidth="normal"
                formContext={form}
                formFieldOptions={{
                  validate: (value) => {
                    const travelHorizonsCheck = form.getValues('travelHorizons');
                    if (travelHorizonsCheck && !value) {
                      return 'Arrival Start date is required';
                    }
                    return true;
                  },
                }}
              />
            </div>
            <div>
              <FormDate
                label={`End Date/Time`}
                fieldName="arrivalEndDate"
                fieldWidth="normal"
                formContext={form}
                formFieldOptions={{
                  validate: (value) => {
                    const arrivalEndDate = value;
                    const arrivalStartDate = getValues('arrivalStartDate');
                    const travelHorizonsCheck = form.getValues('travelHorizons');

                    if (travelHorizonsCheck && !arrivalEndDate) {
                      return 'Arrival End date is required';
                    }

                    if (arrivalStartDate && arrivalEndDate) {
                      const start = new Date(arrivalStartDate);
                      const end = new Date(arrivalEndDate);
                      if (end < start) {
                        return 'Arrival End Date cannot be before Arrival Start Date';
                      }
                    }

                    return true;
                  },
                }}
              />
            </div>
          </Box>

          <Box
            sx={{
              width: '34em',
              height: 220,
              borderRadius: 1,
              padding: 2,
              bgcolor: '#EEEEEE',
            }}
          >
            <div>
              <div style={{ fontWeight: 700, marginBottom: 10 }}>Departure Horizon</div>
              <FormDate
                label={`Start Date/Time`}
                fieldName="departureStartDate"
                fieldWidth="normal"
                formContext={form}
                formFieldOptions={{
                  validate: (value) => {
                    const departureStartDate = value;
                    const arrivalEndDate = getValues('arrivalEndDate');
                    const travelHorizonsCheck = form.getValues('travelHorizons');

                    if (travelHorizonsCheck && !departureStartDate) {
                      return 'Departure Start date is required';
                    }

                    if (arrivalEndDate && departureStartDate) {
                      const arrivalEnd = new Date(arrivalEndDate);
                      const departureStart = new Date(departureStartDate);
                      if (departureStart < arrivalEnd) {
                        return 'Departure Start Date cannot be before Arrival End Date';
                      }
                    }

                    return true;
                  },
                }}
              />
            </div>
            <div>
              <FormDate
                label={`End Date/Time`}
                fieldName="departureEndDate"
                fieldWidth="normal"
                formContext={form}
                formFieldOptions={{
                  validate: (value) => {
                    const departureEndDate = value;
                    const departureStartDate = getValues('departureStartDate');
                    const travelHorizonsCheck = form.getValues('travelHorizons');

                    if (travelHorizonsCheck && !departureEndDate) {
                      return 'Departure End date is required';
                    }

                    if (departureStartDate && departureEndDate) {
                      const start = new Date(departureStartDate);
                      const end = new Date(departureEndDate);
                      if (end < start) {
                        return 'Departure End Date cannot be before Departure Start Date';
                      }
                    }

                    return true;
                  },
                }}
              />
            </div>
          </Box>
        </div>
      </div>

      <Divider sx={{ mt: 4 }} />

      <div style={{ marginBottom: 20 }}>
        <FormInput
          label="Session Name"
          fieldName="name"
          fieldWidth="normal"
          formContext={form}
          defaultValue=""
          formFieldOptions={{
            required: 'This field is required.',
          }}
        />
      </div>

      <SessionMulti
        sx={{ mb: 4 }}
        fieldName="billingCategory"
        form={form}
        fieldWidth="normal"
        defaultValue="none"
        label="Billing Category"
        optionsLoader={api.getBilling}
      />

      <div style={{ width: '60%', marginBottom: 20 }}>
        <FormWysiwyg
          tag="textarea"
          label="Session Description"
          formContext={form}
          fieldName="description"
        />
      </div>

      <div>
        <SessionMulti
          sx={{ mb: 4 }}
          fieldName="trainingLocation"
          form={form}
          label="Training Location"
          optionsLoader={api.getTrainingPrograms}
          req
        />
      </div>

      <SessionMulti
        sx={{ mb: 4 }}
        fieldName="category"
        form={form}
        label="Category / Department"
        optionsLoader={api.getCategories}
        disabled={true}
        req
      />

      <SessionMulti
        fieldName="program"
        form={form}
        label="Program(s)"
        placeholder={'Select Program'}
        optionsLoader={api.getPrograms}
        isSingleSelect={false}
      />

      <div style={{ marginTop: 10, marginBottom: 20 }}>
        <FormCheckBoxGroup
          id="enrollment-approval"
          label="Enrollment Approval"
          name="approvers"
          form={form}
          formFieldOptions={{
            required: 'At least one approver is required.',
          }}
          options={[
            { label: 'Point of Contact', value: 'point-of-contact' },
            { label: 'Program Coordinator', value: 'program-coordinator' },
          ]}
        />
      </div>
    </>
  );
};

export default SessionDetailForm;
