import { useState, useEffect } from 'react';
import axios from 'axios';

const baseUrl = '/admin-api';
const useAdminPrivileges = () => {
  const [requestPending, setRequestPending] = useState(false);
  const [privileges, setPrivileges] = useState([]);
  const [currentRole, setCurrentRole] = useState('');
  useEffect(() => {
    const callApi = async () => {
      setRequestPending(true);

      try {
        const {
          data: { roles, currentRole: role },
        } = await axios.get(`${baseUrl}/getAdminRoles`);
        setCurrentRole(role);
        const priv = roles[role]?.privileges;

        setPrivileges(priv);
      } finally {
        setRequestPending(false);
      }
    };
    if (!privileges.length && !requestPending) callApi();
  }, []);
  return {
    hasPrivilege: (privilege) =>
      !requestPending && (currentRole === 'super_admin' || privileges.includes(privilege)),
  };
};

export default useAdminPrivileges;
