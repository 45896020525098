import React from 'react';
import FormInput from '../../shared/form/components/FormInput';
import { startCase, toNumber } from 'lodash';
import { Table, TableHead, TableBody, TableRow, TableCell, Typography, Box } from '@mui/material';

const Row = ({ name, form, index }) => {
  const minFieldName = `${name}Min`;
  const maxFieldName = `${name}Max`;
  const min = '0';
  const max = '10_000_000';

  const validate = (isMin) => (v) => {
    if (['', null, undefined].includes(v)) return true;
    v = toNumber(v);
    if (v < min) return 'Minimum value is 0';
    if (v > max) return 'Maximum value is 10,000,000';
    let pair = form.getValues()[isMin ? maxFieldName : minFieldName];
    if (!['', null, undefined].includes(pair)) {
      pair = toNumber(pair);
      if (isMin && v > pair) return 'Minimum cannot be greater than Maximum';
      if (!isMin && v < pair) return 'Maximum cannot be less than Minimum';
    }
    return true;
  };

  const inputPropsConfig = {
    label: '',
    fieldWidth: 'short',
    formContext: form,
    type: 'number',
    fullWidth: false,
    sx: {
      width: '230px',
    },
    inputProps: {
      min: '0',
      max: '10000000',
      sx: {
        textAlign: 'center',
        '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
          background: 'transparent',
          border: 'none',
          opacity: 1,
          display: 'block',
        },
        MozAppearance: 'textfield',
      },
    },
  };

  return (
    <TableRow
      sx={{
        backgroundColor: index % 2 === 1 ? 'inherit' : '#EEEEEE',
        padding: 0,
      }}
    >
      <TableCell padding="none">
        <Typography sx={{ marginLeft: '10px' }} variant="body1">
          {startCase(name)}
        </Typography>
      </TableCell>
      {[minFieldName, maxFieldName].map((fieldName, idx) => (
        <TableCell key={fieldName} padding="none" align="center" sx={{ verticalAlign: 'middle' }}>
          <FormInput
            sx={{ width: '300px' }}
            {...inputPropsConfig}
            fieldName={fieldName}
            defaultValue={0}
            formFieldOptions={{
              validate: validate(idx === 0),
              setValueAs: (v) => (v && typeof v === 'string' && !Number.isNaN(v) ? toNumber(v) : v),
            }}
            onChange={() => {
              form.trigger(fieldName);
            }}
            onBlur={() => {
              form.trigger(fieldName);
            }}
          />
        </TableCell>
      ))}
    </TableRow>
  );
};

const AttendeeDetailForm = ({ form }) => {
  const names = ['student', 'instructor', 'assistant'];

  return (
    <>
      <Box
        sx={{
          width: '100%',
          padding: 2,
          fontWeight: 'bold',
          backgroundColor: '#1B76D2',
          color: 'white',
          marginBottom: 2,
        }}
      >
        <Typography sx={{ marginBottom: 0, marginLeft: 1 }}>Attendee Details</Typography>
      </Box>
      <Table sx={{ marginBottom: 3 }}>
        <TableHead>
          <TableRow>
            <TableCell padding="none" sx={{ borderBottom: 'none' }}>
              <Typography sx={{ fontWeight: 'bold', paddingLeft: 1 }}>Attendee Type</Typography>
            </TableCell>
            <TableCell padding="none" sx={{ borderBottom: 'none', textAlign: 'center' }}>
              <Typography sx={{ fontWeight: 'bold' }}>Minimum Seats</Typography>
            </TableCell>
            <TableCell padding="none" sx={{ borderBottom: 'none', textAlign: 'center' }}>
              <Typography sx={{ fontWeight: 'bold' }}>Maximum Seats</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              sx={{ padding: 0, height: 20, borderBottom: '2px solid #747474' }}
              colSpan={3}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {names.map((name, index) => (
            <Row key={name} name={name} form={form} index={index} />
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default AttendeeDetailForm;
