import ContentPaste from '@mui/icons-material/ContentPaste';
import Flight from '@mui/icons-material/Flight';
import MailOutline from '@mui/icons-material/MailOutline';
import SchoolOutlined from '@mui/icons-material/SchoolOutlined';
import { Box } from '@mui/material';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { navigateToPluginPage } from '../../store/rootReducer';
import { ItrsCourseConstants } from '../definitions';
import DashboardItem from './ItrsDashboardItem';

const ItrsDashboardPage = () => {
  const dispatch = useDispatch();
  const nav = useCallback(
    (path) => () => {
      dispatch(
        navigateToPluginPage([ItrsCourseConstants.pluginId, ItrsCourseConstants[`${path}PageId`]])
      );
    },
    [dispatch]
  );

  return (
    <Box
      sx={{
        px: 0,
        mx: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexGrow: 1,
        pt: '4rem',
        height: '100vh',
      }}
    >
      <Box>
        <DashboardItem
          title="Courses"
          description="Manage courses, sessions, enrollments, etc."
          Icon={SchoolOutlined}
          onClick={nav('course')}
        />
        <DashboardItem
          title="Travel requests"
          description="View and confirm travel requests"
          Icon={Flight}
          onClick={nav('travelRequests')}
        />
        <DashboardItem
          title="Reports"
          description="Access detailed summaries, analytics, and insights"
          Icon={ContentPaste}
          onClick={nav('reports')}
        />
        <DashboardItem
          title="Mail log"
          description="View and track the history of email notifications"
          Icon={MailOutline}
          onClick={nav('mailLog')}
        />
      </Box>
    </Box>
  );
};

export default ItrsDashboardPage;
