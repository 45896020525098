import React, { useCallback, useEffect } from 'react';
import FroalaEditor from 'react-froala-wysiwyg';
import { Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { errorGrowl } from '../../../store/rootThunks';
import useWysiwygOptions from '../hooks/useWysiwygOptions';
import FieldWrapper from './FieldWrapper';
const FormWysiwyg = (props) => {
  const { tag, fieldName, formContext, formFieldOptions, growlId } = props;
  const dispatch = useDispatch();
  const { control } = formContext;
  const setError = useCallback(
    (errorMessage = '') => {
      if (errorMessage) dispatch(errorGrowl({ groupId: growlId, props: errorMessage }));
    },
    [dispatch]
  );

  const { wysiwygOptions, error: loadOptionsError } = useWysiwygOptions(setError);
  useEffect(() => {
    if (loadOptionsError) setError(loadOptionsError);
  }, [loadOptionsError, setError]);
  return (
    <FieldWrapper {...props} cn={'form-group'} showLabel showError>
      {wysiwygOptions && (
        <div className="reset-this">
          <Controller
            name={fieldName}
            control={control}
            render={({ field: { value, onChange } }) => (
              <FroalaEditor
                key={fieldName + growlId}
                tag={tag}
                config={wysiwygOptions}
                model={value}
                onModelChange={onChange}
                style={{ minHeight: '300px', width: '100%' }}
              />
            )}
            rules={formFieldOptions}
            {...formFieldOptions}
          />
        </div>
      )}
    </FieldWrapper>
  );
};

export default FormWysiwyg;
